import {
  ISerializedShape,
} from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';

export const groupEntriesByRedactionId = (data: ISerializedShape[]) => {
  return data.reduce((acc, item) => {
    let group = acc.find(({ redactionId }) => redactionId === item.redactionId);

    if (!group) {
      group = {
        ...item,
        entries: [],
      };
      acc.push(group);
    }

    group.entries.push({
      ...item,
    });

    return acc;
  }, []);
};

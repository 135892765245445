import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import CountLabel from '@/dataroom/ui/common/CountLabel';
import allColumns from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/allColumns';
import errorsColumns from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/errorsColumns';
import FilesTable
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/FilesTable/FilesTable';
import TroubleFeedback
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/TroubleFeedback';
import styles from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/uploaderModal.scss';
import {
  ExportToExcelButton,
  variantTypes as exportToExcelButtonVariantTypes,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderForm/ExportToExcelButton';
import { Tab, TabList, TabPanel, Tabs } from '@dealroadshow/uikit';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import linkStyles from '@dealroadshow/uikit/core/styles/links.scss';
import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';
import UploaderProgress from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/UploaderProgress';
import * as tabs from '../constants';

interface IProps {
  openFileDialog: () => void,
  activeTab: string,
  setActiveTab: (tabId: string) => void,
  isUploadedWithErrors: boolean,
  isUploadedWithNoErrors: boolean,
  isUploadOnlyFoldersDone: boolean,
  isPermissionChangeVisible: boolean,
  isUploadComplete: boolean,
}

const UploaderForm = (
  {
    openFileDialog,
    activeTab,
    setActiveTab,
    isUploadedWithErrors,
    isUploadedWithNoErrors,
    isUploadOnlyFoldersDone,
    isPermissionChangeVisible,
    isUploadComplete,
  }: IProps,
) => {
  const {
    allFiles,
    isUploading,
  } = useFileUploaderContext();

  const filesWithErrors = allFiles.filter((file) => file.upload.isFailure() || !isEmpty(file.upload.errors));
  const uploadingFileIndex = allFiles.findIndex((file) => file.upload.isUploading() || file.upload.isJoining());

  const tabsMap = {
    [tabs.ALL]: {
      text: tabs.ALL,
      component: (
        <FilesTable
          isUploadComplete={ isUploadComplete }
          isPermissionChangeVisible={ isPermissionChangeVisible }
          files={ allFiles }
          uploadingFileIndex={ uploadingFileIndex }
          getColumns={ allColumns }
        />
      ),
      isDisabled: false,
      showCount: false,
      count: allFiles.length,
    },
    [tabs.ERRORS]: {
      text: tabs.ERRORS,
      component: (
        <FilesTable
          isUploadComplete={ isUploadComplete }
          isPermissionChangeVisible={ isPermissionChangeVisible }
          files={ filesWithErrors }
          uploadingFileIndex={ uploadingFileIndex }
          getColumns={ errorsColumns }
        />
      ),
      isDisabled: !filesWithErrors.length,
      showCount: true,
      count: filesWithErrors.length, // Most likely it is not used.
    },
  };

  const tabList = Object.keys(tabsMap)
    .map((key) => {
      const tab = tabsMap[key];

      return tab.isDisabled ? null : (
        <Tab
          key={ key }
          tabFor={ key }
          disabled={ tab.isDisabled }
          className={ cn(tabsStyles.tab, styles.buttonsTypes) }
          activeClassName={ tabsStyles.isTabActive }
          disabledClassName={ tabsStyles.isTabDisabled }
          selected={ activeTab === key }
          name={ key }
        >
          <span className={ styles.tabTitle }>{ tab.text }</span>
          <CountLabel
            className={ cn(styles.countLabel, {
              [styles.isActive]: activeTab === key,
            }) }
            totalCount={ filesWithErrors.length }
            canShowCount={ tab.showCount && !!filesWithErrors.length }
          />
        </Tab>
      );
    });

  return (
    <>
      <UploaderProgress isErrorTab={ activeTab === tabs.ERRORS } />
      <div className={ styles.sectionWrp }>
        <Tabs
          defaultTab={ activeTab }
          dataTest="uploaderModalFormTabs"
          onChange={ setActiveTab }
        >
          <div className={ styles.typesContainer }>
            <TabList dataTest="uploadTabLabels">{ tabList }</TabList>
            <div className={ styles.headerButtonsContainer }>
              <TroubleFeedback />
              { (isUploading || isUploadComplete) && (
                // @ts-ignore TODO create task to fix this
                <ExportToExcelButton
                  className={ styles.exportToExcelButton }
                  exportName="file_upload"
                  variant={ exportToExcelButtonVariantTypes.icon }
                />
              ) }
            </div>
          </div>
          <div className={ tabsStyles.tabsFooter } />
          <div className={ styles.tableWrapper }>
            <TabPanel
              key={ activeTab }
              tabId={ activeTab }
              dataTest={ `${ activeTab }TabContent` }
            >
              { tabsMap[activeTab].component }
            </TabPanel>
          </div>
        </Tabs>
        { !isUploading && !isUploadedWithErrors && !isUploadedWithNoErrors && !isUploadOnlyFoldersDone && (
          <div className={ cn(cardStyles.cardInner, styles.dropzoneWrp) } data-test="uploadFilesDropzone">
            <div
              className={ styles.dropzone }
              onClick={ () => openFileDialog() }
            >
              <div className={ styles.text }>
                Drag & drop files and folders or <span className={ linkStyles.link }>select files</span>
              </div>
            </div>
          </div>
        ) }
      </div>
    </>
  );
};

export default React.memo(UploaderForm);

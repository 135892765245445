import React from 'react';
import cn from 'classnames';
import { Tooltip } from '@dealroadshow/uikit';
import EmailAlertsTextTooltipContent from './EmailAlertsTextTooltipContent';
import { EmailAlertsText, DigestEmailAlertsText } from './EmailAlertsText';
import styles from './emailAlertsTextContent.scss';
import { device } from '@/Framework/browser/device';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import { IFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IDigestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { IAccessRequestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';
import screenVariables from '@dealroadshow/uikit/core/styles/screen/screen.scss';

interface IProps {
  wrapperClassName?: string,
  emailAlertsContainerClassName?: string,
  timezone: ITimezone,
  fileUploadAlerts: IFileUploadEmailAlerts,
  stagingFileUploadAlerts: IStagingFileUploadEmailAlerts,
  digestAlerts: IDigestEmailAlerts,
  accessRequestAlerts: IAccessRequestEmailAlerts,
  showAlertsModal: () => void,
  qnaAlerts: IQuestionsEmailAlerts,
  dataTest?: string,
  hideWhenEmpty?: boolean,
}

const isAlertsEnabled = (alertSettings: IFileUploadEmailAlerts |
  IStagingFileUploadEmailAlerts |
  IAccessRequestEmailAlerts |
  IQuestionsEmailAlerts,
) => (
  alertSettings?.realtime?.enabled || alertSettings?.daily?.enabled || alertSettings?.weekly?.enabled
);

const isDigestAlertsEnabled = (alertSettings: IDigestEmailAlerts) => (
  alertSettings?.daily?.enabled || alertSettings?.weekly?.enabled
);

const EmailAlertsTextContent = ({
  wrapperClassName,
  emailAlertsContainerClassName,
  timezone,
  fileUploadAlerts,
  stagingFileUploadAlerts,
  digestAlerts,
  accessRequestAlerts,
  showAlertsModal,
  qnaAlerts,
  dataTest = 'alerts',
  hideWhenEmpty,
}: IProps) => {
  if (
    hideWhenEmpty &&
    !isAlertsEnabled(fileUploadAlerts) &&
    !isAlertsEnabled(stagingFileUploadAlerts) &&
    !isDigestAlertsEnabled(digestAlerts) &&
    !isAlertsEnabled(accessRequestAlerts) &&
    !isAlertsEnabled(qnaAlerts)
  ) {
    return <div className={ styles.emailAlertsWrp } onClick={ showAlertsModal }>None</div>;
  }

  const isMobile = !!device().mobile() && window.innerWidth <= parseInt(screenVariables.screenXs);

  return (
    <div className={ cn(styles.emailAlertsWrp, wrapperClassName) }>
      <Tooltip
        disabled={ isMobile }
        content={ (
          <EmailAlertsTextTooltipContent
            timezone={ timezone }
            fileUploadAlerts={ fileUploadAlerts }
            stagingFileUploadAlerts={ stagingFileUploadAlerts }
            digestAlerts={ digestAlerts }
            accessRequestAlerts={ accessRequestAlerts }
            qnaAlerts={ qnaAlerts }
          />
        ) }
        containerClassName={ styles.emailAlertsTooltipContainer }
      >
        <span
          className={ cn(styles.emailAlertsContainer, emailAlertsContainerClassName) }
          data-test={ dataTest }
          onClick={ showAlertsModal }
        >
          { (!hideWhenEmpty || isAlertsEnabled(fileUploadAlerts)) && (
            <span className={ styles.rowWrp }>
              <span>File: </span>
              <EmailAlertsText
                alertSettings={ fileUploadAlerts }
                isShort
              />
            </span>
          ) }
          { digestAlerts && (!hideWhenEmpty || isDigestAlertsEnabled(digestAlerts)) && (
            <span className={ styles.rowWrp }>
              <span> Digest: </span>
              <DigestEmailAlertsText
                alertSettings={ digestAlerts }
                isShort
              />
            </span>
          ) }
          { stagingFileUploadAlerts && (!hideWhenEmpty || isAlertsEnabled(stagingFileUploadAlerts)) && (
            <span className={ styles.rowWrp }>
              <span> Staging: </span>
              <EmailAlertsText
                alertSettings={ stagingFileUploadAlerts }
                isShort
              />
            </span>
          ) }
          { accessRequestAlerts && (!hideWhenEmpty || isAlertsEnabled(accessRequestAlerts)) && (
            <span className={ styles.rowWrp }>
              <span> Request: </span>
              <EmailAlertsText
                alertSettings={ accessRequestAlerts }
                isShort
              />
            </span>
          ) }
          { qnaAlerts && (!hideWhenEmpty || isAlertsEnabled(qnaAlerts)) && (
            <span className={ styles.rowWrp }>
              <span> Q&A: </span>
              <EmailAlertsText
                alertSettings={ qnaAlerts }
                isShort
              />
            </span>
          ) }
        </span>
      </Tooltip>
    </div>
  );
};

export default EmailAlertsTextContent;

import React from 'react';
import cn from 'classnames';
import { Clearfix, Spinner, Tooltip, responsiveModalStyles } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import ChangePermissionsButton from '../ChangePermissionsButton';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';

import styles from './formSection.scss';

interface IProps {
  pristine: boolean,
  hasValidationErrors?: boolean,
  hasSubmitErrors?: boolean,
  modifiedSinceLastSubmit?: boolean,
  isFetching: boolean,
  closeModal: () => void,
  isPermissionsSectionVisible: boolean,
  isUserAdminLite: boolean,
  userHasSystemManageAccess: boolean,
  onShowPermissionsSection: () => void,
  children: React.ReactNode,
  name: string,
  className?: string,
  footerRefCallback: () => void,
}

const EditFileSection = (
  {
    pristine,
    hasValidationErrors,
    hasSubmitErrors,
    modifiedSinceLastSubmit,
    isFetching,
    closeModal,
    isPermissionsSectionVisible,
    isUserAdminLite,
    userHasSystemManageAccess,
    onShowPermissionsSection,
    children,
    name,
    className,
    footerRefCallback,
  }: IProps,
) => {
  const { tenant } = useDataroomTenantContext();
  const { isPermissionGroupsCreationEnabled } = DataroomTenantConfig.fromCode(tenant).options;
  const isSubmitButtonDisabled =
    pristine || hasValidationErrors || (hasSubmitErrors && !modifiedSinceLastSubmit) || isFetching;

  return (
    <>
      <div
        className={ cn(className, styles.contentWrp, {
          [styles.permissionsSectionVisible]: isPermissionsSectionVisible,
        }) }
      >
        { children }
        <Spinner
          overlay
          isVisible={ isFetching }
        />
      </div>
      {
        !isPermissionsSectionVisible && (
          <div
            className={ cn(responsiveModalStyles.modalFooter, styles.footer) }
            ref={ footerRefCallback }
          >
            <Button
              type="submit"
              variant={ variantTypes.action }
              disabled={ isSubmitButtonDisabled }
              title="Save"
              dataTest={ `${ name }SubmitButton` }
            />
            <Button
              variant={ variantTypes.text }
              onClick={ closeModal }
              title="Cancel"
              dataTest={ `${ name }CancelButton` }
            />
            { isPermissionGroupsCreationEnabled && userHasSystemManageAccess && (
              <Tooltip
                containerClassName={ styles.tooltipContainer }
                content={ 'Not available based on your permissions' }
                disabled={ !isUserAdminLite }
                placement="top"
              >
                <ChangePermissionsButton
                  onShowPermissionsSection={ onShowPermissionsSection }
                  disabled={ isFetching || isUserAdminLite }
                />
              </Tooltip>
            ) }
            <Clearfix />
          </div>
        )
      }
    </>
  );
};

export default EditFileSection;

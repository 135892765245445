import {
  DrawingState,
  ISerializedShape,
} from '@/Framework/UI/Organisms/DocumentViewer/plugins/RedactionPlugin/types';
import { IRedactionAction, RedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';
import { RedactionStructureType } from '@/dataroom/domain/vo/redaction/RedactionStructureType';
import {
  groupEntriesByRedactionId,
} from '@/dataroom/application/redaction/helpers/groupEntriesByRedactionId';

const getFloat = (value: number) => Number(value.toFixed(2));

export const convertShapesToActions = (shapes: ISerializedShape[], oldRedactionsIds: string[]): IRedactionAction[] => {
  const appliedShapesList = shapes.filter((shape) => shape.drawingState === DrawingState.Applied);
  const pendingShapesList = shapes.filter((shape) => shape.drawingState === DrawingState.Pending);

  const entryToDelete = oldRedactionsIds
    .filter((id) => !appliedShapesList.some((shape) => shape.id === id))
    .map((id) => ({ entryId: id })) as (Omit<ISerializedShape, 'id'> & { entryId: string })[];

  const convertStructure = groupEntriesByRedactionId(pendingShapesList)
    .map((item) => ({
      action: RedactionAction.Add,
      type: RedactionStructureType.Redaction,
      data: [
        {
          type: item.type,
          label: item.label,
          entries: item.entries.map((entry, index) => ({
            entryNumber: index,
            frames: entry.controlPoints.map((point) => ({
              topLeftX: getFloat(Math.min(point.x, point.x1)),
              topLeftY: getFloat(Math.min(point.y, point.y1)),
              bottomRightX: getFloat(Math.max(point.x, point.x1)),
              bottomRightY: getFloat(Math.max(point.y, point.y1)),
              pageIndex: point.pageIndex,
            })),
          })),
        },
      ],
    }));

  return [
    ...(entryToDelete.length
        ? [{
          type: RedactionStructureType.Entry,
          action: RedactionAction.Delete,
          data: entryToDelete,
        }]
        : []
    ),
    ...convertStructure,
  ];
};

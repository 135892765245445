import env from '@/Framework/env/env';
import { IDataroomTenantConfig } from '@/dataroom/domain/vo/DataroomTenantConfig';
import { featureToggle as DataroomFeatureToggle } from '@/dataroom/application/config/featureToggle';

type TTenantConfigKey = 'tenantDealvdr' | 'tenant17g5' | 'tenantInvestorSet' | 'tenantVeriSend' | 'tenantResearchRoom';

const domain = {
  tenantDealvdrDomain: env('TENANT_DEALVDR___DATAROOM__DOMAIN'),
  tenant17g5Domain: env('TENANT_17G5___DATAROOM__DOMAIN'),
  tenantInvestorSetDomain: env('TENANT_INVESTORSET___DATAROOM__DOMAIN'),
  tenantVeriSendDomain: env('TENANT_VERISEND___DATAROOM__DOMAIN'),
  tenantResearchRoomDomain: env('TENANT_RESEARCHROOM___DATAROOM__DOMAIN'),
};

const tenant: {
  [tenant in TTenantConfigKey]: IDataroomTenantConfig
} = {
  tenantDealvdr: {
    name: 'DealVDR',
    code: 'dealvdr',
    abbr: 'VDR',
    supportEmail: env('CREDENTIALS__TENANT_DEALVDR__SUPPORT__EMAIL'),
    productionHost: 'dealvdr.com',
    supportPhoneNumber: env('CREDENTIALS__TENANT_DEALVDR__SUPPORT__PHONE'),
    salesEmail: env('CREDENTIALS__TENANT_DEALVDR__SUPPORT__SALES_EMAIL'),
    alertsEmail: env('CREDENTIALS__TENANT_DEALVDR__SUPPORT__ALERTS_EMAIL'),
    hostname: `${ domain.tenantDealvdrDomain }`,
    conferencePhoneNumber: env('CREDENTIALS__TENANT_DEALVDR__SUPPORT__CONFERENCE_PHONE'),
    useApiProxy: true,
    featureToggle: DataroomFeatureToggle,
    ga: {
      trackingId: env('GA__TRACKING_ID_TENANT_DEALVDR'),
    },
    gtm: {
      trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_DEALVDR__GTM_ID'),
    },
    supportChatId: env('FRONT_APP__CHAT_ID_TENANT_DEALVDR'),
    api: {
      internal: `http://${ env('TENANT_DEALVDR_WS__HOSTNAME_INTERNAL') }`,
    },
    options: {
      isDataroom: true,
      isNewApp: false,
      isManageAccessRequestsEnabled: false,
      isManageWhitelistEnabled: false,
      isPublicListingOptionEnabled: false,
      isTransactionTypeEnabled: true,
      isEnhancedTaggingSectionRequired: false,
      isDigestAlertsEnabled: true,
      isAnalyticsEnabled: true,
      isEmailUploadCustomFoldersEnabled: true,
      isDocumentationStateEnabled: false,
      isTwoFactorOptionEnabled: true,
      isAudioUploadingEnabled: true,
      isStagingModuleAvailable: true,
      hasViewIntDialIns: true,
      isQnAEnabled: true,
      visibleSectorForTransactionTypeList: [
        '900c29f7-5068-45a9-a136-c392101b9b84', // SECURITIZATION
        '20478fc8-107d-4e1f-bf74-095c4ae26179', // OTHER
      ],
      tenantUrlPrefix: env('TENANT_DEALVDR___DATAROOM__PREFIX_URL'),
      tenantLabelSm: 'data room',
      tenantLabel: 'Data Room',
      tenantPathName: 'dataroom',
      tenantSettingsPrefix: 'data-room-settings',
      isFileStorageRegionSelectEnabled: true,
      isStagesEnabled: false,
      isInitialTimeZoneEnabled: false,
      isInvestorPermissions: false,
      isPermissionGroupsCreationEnabled: true,
    },
    isConsentForEDeliveryRequired: false,
    sftp: {
      port: 2222,
    },
  },
  tenant17g5: {
    name: '17g5.com',
    code: '17g5',
    abbr: '17G',
    supportEmail: env('CREDENTIALS__TENANT_17G5__SUPPORT__EMAIL'),
    productionHost: '17g5.com',
    supportPhoneNumber: env('CREDENTIALS__TENANT_17G5__SUPPORT__PHONE'),
    salesEmail: env('CREDENTIALS__TENANT_17G5__SUPPORT__SALES_EMAIL'),
    alertsEmail: env('CREDENTIALS__TENANT_17G5__SUPPORT__ALERTS_EMAIL'),
    hostname: `${ domain.tenant17g5Domain }`,
    conferencePhoneNumber: env('CREDENTIALS__TENANT_17G5__SUPPORT__CONFERENCE_PHONE'),
    useApiProxy: true,
    featureToggle: DataroomFeatureToggle,
    ga: {
      trackingId: env('GA__TRACKING_ID_TENANT_17G5'),
    },
    gtm: {
      trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_17G5__GTM_ID'),
    },
    supportChatId: env('FRONT_APP__CHAT_ID_TENANT_17G5'),
    api: {
      internal: `http://${ env('TENANT_17G5_WS__HOSTNAME_INTERNAL') }`,
    },
    options: {
      isDataroom: true,
      isNewApp: false,
      isManageAccessRequestsEnabled: false,
      isManageWhitelistEnabled: false,
      isPublicListingOptionEnabled: false,
      isTransactionTypeEnabled: false,
      isEnhancedTaggingSectionRequired: false,
      isDigestAlertsEnabled: false,
      isAnalyticsEnabled: false,
      isEmailUploadCustomFoldersEnabled: false,
      isDocumentationStateEnabled: false,
      isTwoFactorOptionEnabled: true,
      isAudioUploadingEnabled: true,
      isStagingModuleAvailable: true,
      hasViewIntDialIns: true,
      isQnAEnabled: false,
      visibleSectorForTransactionTypeList: [],
      tenantUrlPrefix: env('TENANT_17G5___DATAROOM__PREFIX_URL'),
      tenantLabelSm: 'data room',
      tenantLabel: 'Data Room',
      tenantPathName: 'dataroom',
      tenantSettingsPrefix: 'data-room-settings',
      isFileStorageRegionSelectEnabled: false,
      isStagesEnabled: false,
      isInitialTimeZoneEnabled: false,
      isInvestorPermissions: false,
      isPermissionGroupsCreationEnabled: true,
    },
    isConsentForEDeliveryRequired: false,
  },
  tenantInvestorSet: {
    name: 'InvestorSet',
    code: 'investorset',
    abbr: 'INV',
    supportEmail: env('CREDENTIALS__TENANT_INVESTORSET__SUPPORT__EMAIL'),
    productionHost: 'investorset.com',
    supportPhoneNumber: env('CREDENTIALS__TENANT_INVESTORSET__SUPPORT__PHONE'),
    salesEmail: env('CREDENTIALS__TENANT_INVESTORSET__SUPPORT__SALES_EMAIL'),
    alertsEmail: env('CREDENTIALS__TENANT_INVESTORSET__SUPPORT__ALERTS_EMAIL'),
    hostname: `${ domain.tenantInvestorSetDomain }`,
    conferencePhoneNumber: env('CREDENTIALS__TENANT_INVESTORSET__SUPPORT__CONFERENCE_PHONE'),
    useApiProxy: true,
    featureToggle: DataroomFeatureToggle,
    ga: {
      trackingId: env('GA__TRACKING_ID_TENANT_INVESTORSET'),
    },
    gtm: {
      trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_INVESTORSET__GTM_ID'),
    },
    supportChatId: env('FRONT_APP__CHAT_ID_TENANT_INVESTORSET'),
    api: {
      internal: `http://${ env('TENANT_INVESTORSET_WS__HOSTNAME_INTERNAL') }`,
    },
    options: {
      isDataroom: true,
      isNewApp: false,
      isManageAccessRequestsEnabled: true,
      isManageWhitelistEnabled: true,
      isPublicListingOptionEnabled: true,
      isTransactionTypeEnabled: true,
      isEnhancedTaggingSectionRequired: true,
      isDigestAlertsEnabled: true,
      isAnalyticsEnabled: true,
      isEmailUploadCustomFoldersEnabled: true,
      isDocumentationStateEnabled: false,
      isTwoFactorOptionEnabled: false,
      isAudioUploadingEnabled: false,
      isStagingModuleAvailable: true,
      hasViewIntDialIns: false,
      isQnAEnabled: false,
      visibleSectorForTransactionTypeList: [
        '1a0c0874-21dd-48f8-9288-6c7c103ed1e0', // ASSET-BACKED SECURITY
      ],
      tenantUrlPrefix: env('TENANT_INVESTORSET___DATAROOM__PREFIX_URL'),
      tenantLabelSm: 'InvestorSet',
      tenantLabel: 'InvestorSet',
      tenantPathName: 'investorset',
      tenantSettingsPrefix: 'investorset-settings',
      isFileStorageRegionSelectEnabled: true,
      isStagesEnabled: false,
      isInitialTimeZoneEnabled: false,
      isInvestorPermissions: false,
      isPermissionGroupsCreationEnabled: true,
    },
    isConsentForEDeliveryRequired: false,
  },
  tenantVeriSend: {
    name: 'VeriSend',
    code: 'verisend',
    abbr: 'VRS',
    supportEmail: env('CREDENTIALS__TENANT_VERISEND__SUPPORT__EMAIL'),
    productionHost: 'verisend.com',
    supportPhoneNumber: env('CREDENTIALS__TENANT_VERISEND__SUPPORT__PHONE'),
    salesEmail: env('CREDENTIALS__TENANT_VERISEND__SUPPORT__SALES_EMAIL'),
    alertsEmail: env('CREDENTIALS__TENANT_VERISEND__SUPPORT__ALERTS_EMAIL'),
    hostname: `${ domain.tenantVeriSendDomain }`,
    conferencePhoneNumber: null,
    featureToggle: DataroomFeatureToggle,
    useApiProxy: true,
    ga: {
      trackingId: env('GA__TRACKING_ID_TENANT_VERISEND'),
    },
    gtm: {
      trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_VERISEND__GTM_ID'),
    },
    supportChatId: env('FRONT_APP__CHAT_ID_TENANT_VERISEND'),
    api: {
      internal: `http://${ env('TENANT_VERISEND_WS__HOSTNAME_INTERNAL') }`,
    },
    options: {
      isDataroom: true,
      isNewApp: false,
      isManageAccessRequestsEnabled: true,
      isManageWhitelistEnabled: true,
      isPublicListingOptionEnabled: true,
      isTransactionTypeEnabled: true,
      isEnhancedTaggingSectionRequired: true,
      isDigestAlertsEnabled: true,
      isAnalyticsEnabled: true,
      isEmailUploadCustomFoldersEnabled: true,
      isDocumentationStateEnabled: true,
      isTwoFactorOptionEnabled: true,
      isAudioUploadingEnabled: false,
      isStagingModuleAvailable: false,
      hasViewIntDialIns: false,
      isQnAEnabled: false,
      visibleSectorForTransactionTypeList: [
        'f333a22e-ed64-4ebb-b261-9ff8010face4', // ASSET-BACKED SECURITY
      ],
      tenantUrlPrefix: env('TENANT_VERISEND___DATAROOM__PREFIX_URL'),
      tenantLabelSm: 'VeriSend',
      tenantLabel: 'VeriSend',
      tenantPathName: 'verisend',
      tenantSettingsPrefix: 'verisend-settings',
      isFileStorageRegionSelectEnabled: true,
      isStagesEnabled: false,
      isInitialTimeZoneEnabled: false,
      isInvestorPermissions: false,
      isPermissionGroupsCreationEnabled: true,
    },
    isConsentForEDeliveryRequired: true,
  },
  tenantResearchRoom: {
    name: 'ResearchRoom',
    code: 'researchroom',
    abbr: 'RSR',
    supportEmail: env('CREDENTIALS__TENANT_RESEARCHROOM__SUPPORT__EMAIL'),
    productionHost: 'researchroom.com',
    supportPhoneNumber: env('CREDENTIALS__TENANT_RESEARCHROOM__SUPPORT__PHONE'),
    salesEmail: env('CREDENTIALS__TENANT_RESEARCHROOM__SUPPORT__SALES_EMAIL'),
    alertsEmail: env('CREDENTIALS__TENANT_RESEARCHROOM__SUPPORT__ALERTS_EMAIL'),
    hostname: `${ domain.tenantResearchRoomDomain }`,
    conferencePhoneNumber: null,
    featureToggle: DataroomFeatureToggle,
    useApiProxy: true,
    ga: {
      trackingId: env('GA__TRACKING_ID_TENANT_RESEARCHROOM'),
    },
    gtm: {
      trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_RESEARCHROOM__GTM_ID'),
    },
    supportChatId: env('FRONT_APP__CHAT_ID_TENANT_RESEARCHROOM'),
    api: {
      internal: `http://${ env('TENANT_RESEARCHROOM_WS__HOSTNAME_INTERNAL') }`,
    },
    options: {
      isDataroom: true,
      isNewApp: false,
      isManageAccessRequestsEnabled: true,
      isManageWhitelistEnabled: true,
      isPublicListingOptionEnabled: true,
      isTransactionTypeEnabled: false,
      isEnhancedTaggingSectionRequired: true,
      isDigestAlertsEnabled: true,
      isAnalyticsEnabled: true,
      isEmailUploadCustomFoldersEnabled: true,
      isDocumentationStateEnabled: true,
      isTwoFactorOptionEnabled: true,
      isAudioUploadingEnabled: false,
      isStagingModuleAvailable: false,
      hasViewIntDialIns: false,
      isQnAEnabled: false,
      visibleSectorForTransactionTypeList: [
        'f333a22e-ed64-4ebb-b261-9ff8010face4', // ASSET-BACKED SECURITY
      ],
      tenantUrlPrefix: env('TENANT_RESEARCHROOM___DATAROOM__PREFIX_URL'),
      tenantLabelSm: 'ResearchRoom',
      tenantLabel: 'ResearchRoom',
      tenantPathName: 'researchroom',
      tenantSettingsPrefix: 'researchroom-settings',
      isFileStorageRegionSelectEnabled: true,
      isStagesEnabled: true,
      isInitialTimeZoneEnabled: true,
      isInvestorPermissions: true,
      isPermissionGroupsCreationEnabled: false,
    },
    isConsentForEDeliveryRequired: true,
  },
};

export default {
  domain,
  tenant,
};

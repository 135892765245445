import React, { useEffect, useState, useCallback, useRef } from 'react';
import cn from 'classnames';
import { getUnixTimestamp } from '@/Framework/DateTime/getUnixTimestamp';
import useRouter from '@/Framework/hooks/useNextRouter';
import { Resizable } from 're-resizable';
import throttle from 'lodash/throttle';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import * as questionPermissions from '@/dataroom/domain/questionPermissions';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { LAYOUT_CONTENT_WRP_ID } from '@/dataroom/ui/components/Dataroom/DataroomLayout/LayoutContentWrp';
import { Tab, TabList, TabPanel, Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import styles from './sidebar.scss';
import Settings from '@/dataroom/ui/components/Dataroom/Sidebar/Settings';
import Questions from '@/dataroom/ui/components/Dataroom/Sidebar/Questions';
import Navigation from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/Navigation';
import Footer from '@/dataroom/ui/components/Dataroom/Sidebar/Footer';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import { useSidebarTabContext } from '@/dataroom/application/SidebarTabContext';
import emitSidebarResizeEvent from '@/dataroom/ui/components/Dataroom/Sidebar/eventEmitters/emitSidebarResizeEvent';
import { SidebarTab } from './constants';
import DialInsSection from '@/dataroom/ui/components/Dataroom/components/DialInsSection';
import screenVariables from '@dealroadshow/uikit/core/styles/screen/screen.scss';
import config from '@/dataroom/application/config/config';
import logoDealVDR from '@/dataroom/ui/assets/dealvdrLogoTemplate.svg';
import logo17g5 from '@/dataroom/ui/assets/17g5LogoTemplate.svg';
import logoInvestorSet from '@/dataroom/ui/assets/investorSetLogoTemplate.svg';
import logoVeriSend from '@/dataroom/ui/assets/veriSendLogoTemplate.svg';
import logoResearchRoom from '@/dataroom/ui/assets/researchRoomLogoTemplate.svg';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { AccessRequestStatus } from '@/dataroom/ui/components/Settings/ManageRequests/constants';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import Dataroom from '@/dataroom/domain/Dataroom';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import { Area } from '@/dataroom/domain/vo/Area';

const DEFAULT_SIDEBAR_WIDTH = 280;
const RESIZE_THROTTLING_DELAY = 100;
const TOGGLE_TRANSITION_SCALE = 600;

const logoMapping = {
  [config.tenant.tenantDealvdr.code]: logoDealVDR,
  [config.tenant.tenant17g5.code]: logo17g5,
  [config.tenant.tenantInvestorSet.code]: logoInvestorSet,
  [config.tenant.tenantVeriSend.code]: logoVeriSend,
  [config.tenant.tenantResearchRoom.code]: logoResearchRoom,
};

const getTabs = (dataroom: IDataroom, tenant: DataroomTenant, canUserAccessPrimary: boolean) => {
  const { tenantUrlPrefix, isManageAccessRequestsEnabled } = DataroomTenantConfig.fromCode(tenant).options;

  return [
    {
      key: SidebarTab.Dataroom,
      path: `/${ tenantUrlPrefix }/${ dataroom.name }/${
        canUserAccessPrimary ? dataroom.rootFolderId : `/${ Area.Staging }/${ dataroom.stagingRootFolderId }`
      }`,
      icon: IconType.folderOpen,
      component: (<Navigation />),
      tooltip: `View ${ DataroomTenantConfig.fromCode(tenant).options.tenantLabel }`,
    },
    {
      key: SidebarTab.Questions,
      path: `/${ tenantUrlPrefix }/${ dataroom.name }/questions/open-questions`,
      icon: IconType.chat,
      component: (<Questions />),
      tooltip: 'View Q&A',
      accessKey: 'question',
    },
    {
      key: SidebarTab.Settings,
      path: (
        !!isManageAccessRequestsEnabled && !Dataroom.isDataroomOpenAccess(dataroom)
          ? `/${ tenantUrlPrefix }/${ dataroom.name }/settings/manage-access-requests/${ AccessRequestStatus.Pending }`
          : `/${ tenantUrlPrefix }/${ dataroom.name }/settings/users-permissions`
      ),
      icon: IconType.settings,
      // @ts-ignore
      component: (<Settings />),
      tooltip: 'Manage Users & Settings',
      accessKey: 'settings',
    },
  ];
};

interface IProps {
  isCollapsed?: boolean,
  isMobileMenuCollapsed: boolean,
  setMobileMenuCollapsed: (c: boolean) => void,
}

const Sidebar = ({ isCollapsed, isMobileMenuCollapsed, setMobileMenuCollapsed }: IProps) => {
  const [isToggling, setIsToggling] = useState(false);
  const checkMobile = () => window.innerWidth <= parseInt(screenVariables.screenS);
  const [isMobile, setIsMobile] = useState(checkMobile());
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(isCollapsed);
  const [dataroomLogoUrl, setDataroomLogoUrl] = useState(null);

  const { push } = useRouter();

  const { dataroom } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();
  const { currentUser, canUserAccessPrimary } = useCurrentUserContext();
  const { activeSidebarTab } = useSidebarTabContext();
  const { turnOffDnDFileIndexesMode } = useDnDFileIndexesContext();
  const wrapperRef = useRef(null);

  const userHasSystemManageAccess = (
    managePermissions.canManageSettings(currentUser) || managePermissions.canManageSettingsLite(currentUser)
  );
  const userHasQuestionAccess = questionPermissions.hasQuestionAccess(currentUser);

  const tabAccess = {
    question: userHasQuestionAccess && !!dataroom.qnaModule,
    settings: userHasSystemManageAccess,
  };

  const [mobileActiveTab, setMobileActiveTab] = useState(activeSidebarTab);

  const resizeHandler = useCallback(
    throttle(() => {
      const layoutContentElement = document.getElementById(LAYOUT_CONTENT_WRP_ID);
      setIsMobile(checkMobile());

      if (
        !checkMobile() &&
        layoutContentElement?.scrollWidth > layoutContentElement?.offsetWidth &&
        !isSidebarCollapsed
      ) {
        setSidebarCollapsed(true);
        emitSidebarResizeEvent(true);
        setTimeout(emitSidebarResizeEvent, TOGGLE_TRANSITION_SCALE);
      }
    }, RESIZE_THROTTLING_DELAY),
    [],
  );

  useEffect(() => {
    setMobileActiveTab(activeSidebarTab);
    if (activeSidebarTab === SidebarTab.Settings) {
      turnOffDnDFileIndexesMode();
    }
  }, [activeSidebarTab]);

  useEffect(() => {
    dataroom.logoUrl && setDataroomLogoUrl(
      `${ dataroomUrl(tenant).getFileUrl(dataroom.logoUrl) }?t=${ getUnixTimestamp() }`,
    );
  }, [dataroom]);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  const handleResize = () => {
    emitSidebarResizeEvent();
    setIsToggling(false);
  };

  const handleTabChange = (tab) => {
    if (checkMobile()) {
      setMobileActiveTab(tab.key);
    } else {
      push(tab.path);
    }
  };

  const handleToggleCollapseSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
    setIsToggling(true);
    emitSidebarResizeEvent(true);
    setTimeout(emitSidebarResizeEvent, TOGGLE_TRANSITION_SCALE);
  };

  const tabCollection = getTabs(dataroom, tenant, canUserAccessPrimary).filter(({ accessKey }) => (
    accessKey ? tabAccess[accessKey] : true
  ));

  const getDataTestAttributes = (str: string) => {
    const arrWithoutUnderscore = str.toLowerCase().split('_');
    const formatToArr = arrWithoutUnderscore.map((el, i) => (
      (i !== 0) ? el.charAt(0).toUpperCase() + el.slice(1) : el));
    return formatToArr.join('');
  };

  const tabs = tabCollection.map((tab) => {
    const isTabSelected = isMobile ? mobileActiveTab === tab.key : activeSidebarTab === tab.key;
    return (
      <div
        key={ tab.key }
        className={ styles.tabItemWrp }
      >
        <Tab
          tabFor={ tab.key }
          className={ styles.tabItem }
          activeClassName={ styles.isTabActive }
          onClick={ () => handleTabChange(tab) }
          selected={ isTabSelected }
          name={ getDataTestAttributes(tab.key) }
        >
          <Tooltip
            content={ tab.tooltip }
            hideOnClick
            maxWidth="auto"
            containerClassName={ styles.tooltipContainer }
          >
            <Icon
              type={ tab.icon }
              className={ styles.tabItemIcon }
            />
          </Tooltip>
        </Tab>
        <TabPanel
          tabId={ tab.key }
          className={ styles.tabContentWrp }
          selected={ isTabSelected }
          dataTest={ `${ getDataTestAttributes(tab.key) }TabContent` }
        >
          <div className={ styles.tabWrp }>
            { isMobile ? (
              <div className={ styles.mobileDataroomName }>
                { dataroom.displayName }
              </div>
              )
              : (
                <div className={ styles.logoWrp }>
                  <img
                    src={ dataroomLogoUrl || logoMapping[tenant] }
                    className={ cn({
                      [styles.isTemplate]: !dataroomLogoUrl,
                    }) }
                    alt="logo"
                  />
                </div>
              ) }
            <div
              className={ cn(
                styles.tabContent,
                { [styles.dataroomTabContent]: !isMobile && activeSidebarTab === SidebarTab.Dataroom },
              ) }
              data-test="tabSections"
            >
              { (isSidebarCollapsed || tab.key === activeSidebarTab || checkMobile()) && tab.component }
            </div>
          </div>
          <div className={ styles.dialInsSectionWrp }>
            <DialInsSection
              className={ styles.dialInsSection }
              isHidden={ mobileActiveTab === SidebarTab.Questions }
              usePortal
            />
          </div>
        </TabPanel>
      </div>
    );
  });

  const sidebar = (
    <div
      className={ cn(styles.sidebar, {
        [styles.noFrame]: mobileActiveTab === SidebarTab.Questions,
      }) }
      data-test="sidebar"
    >
      {
        isMobile ? (
          <span
            className={ styles.closeIconWrp }
            onClick={ () => setMobileMenuCollapsed(true) }
          >
            <Icon
              type={ IconType.close }
              className={ styles.closeIcon }
            />
          </span>
        ) : (
          <div>
            <div className={ styles.logoWrp }>
              <img
                src={ dataroomLogoUrl || logoMapping[tenant] }
                className={ cn({
                  [styles.isTemplate]: !dataroomLogoUrl,
                }) }
                alt="logo"
              />
            </div>
            <div className={ styles.toggleButtonWrp }>
              <Tooltip
                content={ isSidebarCollapsed ? 'Expand' : 'Collapse' }
                hideOnClick
              >
                <div
                  className={ styles.toggleButton }
                  onClick={ handleToggleCollapseSidebar }
                  data-test="toggleSidebarButton"
                >
                  { isSidebarCollapsed ? <Icon type={ IconType.doubleArrowsRight } /> :
                  <Icon isGrey type={ IconType.doubleArrowsLeft } /> }
                </div>

              </Tooltip>
            </div>
          </div>
        )
      }
      <div className={ styles.tabsWrp }>
        <TabList
          className={ styles.tabsList }
          dataTest="sidebarTabLabels"
        >
          { tabs }
        </TabList>
      </div>
      <Footer isCollapsed={ isSidebarCollapsed } />
    </div>
  );

  const checkLink = (node) => {
    if (node === wrapperRef.current) {
      return false;
    }
    if (node?.tagName?.toLowerCase() === 'a') {
      return true;
    }
    if (node.parentNode) {
      return checkLink(node.parentNode);
    }
    return false;
  };

  const handleWrapperClick = (e) => {
    const element = e.target;

    if (element === wrapperRef.current || checkLink(element)) {
      setMobileMenuCollapsed(true);
    }
  };

  return (
    isMobile ? (
      <div
        className={ cn(styles.sidebarWrp, {
          [styles.isMobileCollapsed]: isMobileMenuCollapsed,
        }) }
        onClick={ handleWrapperClick }
        ref={ wrapperRef }
      >
        { sidebar }
      </div>
    ) : (
      <Resizable
        onResize={ handleResize }
        enable={ {
          top: false,
          right: !isSidebarCollapsed,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        } }
        defaultSize={ { width: DEFAULT_SIDEBAR_WIDTH, height: 'auto' } }
        maxWidth="50%"
        minWidth={ isToggling ? 0 : DEFAULT_SIDEBAR_WIDTH }
        handleComponent={ {
          right: (
            <div className={ styles.sliderButtonWrp }>
              <span
                className={ styles.sliderButton }
              >
                <Icon isGrey type={ IconType.slider } />
              </span>
            </div>
          ),
        } }
        className={ cn(styles.sidebarWrp, {
          [styles.isCollapsed]: isSidebarCollapsed,
          [styles.isTransition]: isToggling,
        }) }
      >
        { sidebar }
      </Resizable>
    )
  );
};

export default Sidebar;

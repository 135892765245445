import React, { useState, useEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import FolderTree from './FolderTree';
import Alert from '@/dataroom/ui/components/Dataroom/components/Alert';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Spinner, Tooltip, Icon, IconType, ResponsiveModal as Modal, Checkbox } from '@dealroadshow/uikit';
import AreaTabs from '@/dataroom/ui/common/AreaTabs';
import ChangePermissions from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions';
import ConflictModal from '@/dataroom/ui/common/DataroomExplorer/Modals/ConflictModal/ConflictModal';
import { getNodeItemById, getRootClosestPathByIds, isFolder } from '@/dataroom/domain/filesystem';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { Action } from '@/dataroom/domain/vo/filesystem/Action';
import { getAlert } from './alert';
import { getConfirmation } from './confirmation';
import { Area } from '@/dataroom/domain/vo/Area';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import { canManageSettingsLite } from '@/dataroom/domain/managePermissions';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemConflictResolvingItem } from '@/dataroom/domain/vo/filesystem/FilesystemConflictResolvingItem';
import changePermissionsStyles
  from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangePermissions/changePermissions.scss';
import styles from './copyMoveModal.scss';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';

export const getModalDescription = (actionName, isPlural) => ({
  [Action.Copy]: `Please choose which folder you would like to place
    the copied item${ isPlural ? 's' : '' } in.`,
  [Action.Move]: `Please choose which folder you would like to move
    the selected item${ isPlural ? 's' : '' } to.`,
}[actionName]);

interface IProps {
  fetch: (any) => Promise<void>,
  closeCopyMoveModal: () => void,
  successItems: IFilesystemConflictResolvingItem[],
  conflictItems: IFilesystemConflictResolvingItem[],
  isFetching: boolean,
  title: string,
  actionName: Action,
  conflictType: string,
  filesystemItems: IFolderTree[],
  destinationFolder: IFolderTree,
  setDestinationFolder: (folder: IFolderTree) => void,
}

const CopyMoveModal = (
  {
    closeCopyMoveModal,
    fetch,
    successItems,
    conflictItems,
    isFetching,
    title,
    actionName,
    conflictType,
    filesystemItems,
    destinationFolder,
    setDestinationFolder,
  }: IProps,
) => {
  const { tenant } = useDataroomTenantContext();
  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const { stagingFolderTree } = useStagingFolderTreeContext();
  const { currentUser, canUserAccessStaging, canUserAccessPrimary } = useCurrentUserContext();

  const sourceArea = useRef(primaryFolderTree ? Area.Primary : Area.Staging);
  const fromFolder = useRef(null);
  const [activeArea, setActiveArea] = useState(sourceArea.current);
  const [folderTree, setFolderTree] = useState(
    sourceArea.current === Area.Primary ? primaryFolderTree : stagingFolderTree,
  );
  const [permissions, setPermissions] = useState<IChangePermissionsType>({});
  const {
    allItems,
    selectedItems,
    disabledIds,
    defaultCheckboxGroupName,
    ...permissionGroups
  } = permissions;

  const isUserAdminLite = canManageSettingsLite(currentUser);
  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser) || isUserAdminLite;
  const { isPermissionGroupsCreationEnabled } = DataroomTenantConfig.fromCode(tenant).options;

  const [isPermissionChangeVisible, setIsPermissionChangeVisible] = useState(false);
  const [isUnderstand, setIsUnderstand] = useState(false);

  const fromStagingToPrimary = sourceArea.current === Area.Staging && activeArea === Area.Primary;
  const isTabsShown = canUserAccessStaging && canUserAccessPrimary;

  const tooltipContent = isUserAdminLite ? 'Not available based on your permissions' : 'Please choose the destination folder first';
  useEffect(() => {
    const folderTree = filesystemItems[0].isStaging ? stagingFolderTree : primaryFolderTree;
    sourceArea.current = filesystemItems[0].isStaging ? Area.Staging : Area.Primary;
    fromFolder.current = folderTree && getNodeItemById(folderTree, filesystemItems[0].parentFolderId);
    setActiveArea(sourceArea.current);
  }, [filesystemItems]);

  useEffect(() => {
    setDestinationFolder(null);
    setIsPermissionChangeVisible(false);
    setIsUnderstand(false);
    setFolderTree(activeArea === Area.Primary ? primaryFolderTree : stagingFolderTree);
  }, [activeArea]);

  const onUnderstandChange = (e) => {
    setIsUnderstand(e.target.checked);
  };

  const onPermissionsChange = () => {
    setIsUnderstand(false);
    setIsPermissionChangeVisible(true);
  };

  const closestPath = useMemo(() => (
    getRootClosestPathByIds(
      folderTree,
      filesystemItems.map((item) => (isFolder(item) ? item.id : item.parentFolderId)),
    )
  ), [filesystemItems, folderTree]);

  const [focusNode, ...rootClosestPath] = closestPath;

  const content = (
    <div className={ styles.contentWrp }>
      <div className={ styles.bodyPadding }>
        <Alert>
          { getAlert(tenant, actionName, sourceArea.current, activeArea) }
        </Alert>
      </div>
      <div className={ cn(styles.info, styles.bodyPadding) }>
        <div className={ styles.left }>{ getModalDescription(actionName, filesystemItems.length > 1) }</div>
        <div className={ cn(styles.isDisabled, styles.right) }>
          <Icon
            className={ styles.nodeFolderIcon }
            type={ IconType.folder }
          />
          - You cannot { actionName } into this folder
        </div>
      </div>
      { isTabsShown && (
        <AreaTabs
          className={ styles.tabsWrp }
          activeArea={ activeArea }
          onAreaChange={ setActiveArea }
        />
      ) }
      <FolderTree
        folderTree={ folderTree }
        openNodes={ rootClosestPath.map(({ id }) => id) }
        focusNodeId={ focusNode?.id }
        destinationFolder={ destinationFolder }
        setDestinationFolder={ setDestinationFolder }
        filesystemItems={ filesystemItems }
      />
      { userHasSystemManageAccess && isPermissionChangeVisible && (
        <>
          <div className={ cn(styles.bodyPadding, changePermissionsStyles.description) }>
            The following table displays permissions of the destination folder. The selected files and folders will
            inherit these permissions after this action is performed.
          </div>
          <ChangePermissions
            folderId={ destinationFolder.id }
            onChange={ setPermissions }
            className={ styles.permissionsWrp }
          />
        </>
      ) }
      <Spinner
        isVisible={ isFetching }
        overlay
      />
    </div>
  );

  const footer = (
    <div className={ styles.modalFooter }>
      <Checkbox
        dataTest="understand"
        className={ styles.agree }
        onChange={ onUnderstandChange }
        disabled={ isFetching }
        checked={ isUnderstand }
        label={ getConfirmation(
          tenant,
          actionName,
          sourceArea.current,
          activeArea,
          isPermissionChangeVisible,
        ) }
      />
      <div className={ cn(styles.left, styles.buttonsWrp) }>
        <Button
          type="submit"
          variant={ variantTypes.action }
          onClick={ () => {
            fetch({
              filesystemItems,
              destinationFolder,
              permissionGroups,
              successCallback: closeCopyMoveModal,
              payload: fromStagingToPrimary ? { fromStagingToPrimary } : null,
            });
          } }
          disabled={ !isUnderstand || !destinationFolder || isFetching }
          dataTest="submitButton"
        >
          { isPermissionChangeVisible && (<>Save Permissions & </>) }
          <span className={ styles.capitalize }>{ actionName }</span>
        </Button>
        <Button
          variant={ variantTypes.text }
          onClick={ closeCopyMoveModal }
          title="Cancel"
          dataTest="cancelButton"
        />
      </div>
      { isPermissionGroupsCreationEnabled && userHasSystemManageAccess && !isPermissionChangeVisible && (
        <div className={ styles.right }>
          <Tooltip
            placement="top"
            content={ tooltipContent }
            disabled={ !!destinationFolder && !isUserAdminLite }
            hideOnClick
          >
            <Button
              className={ styles.changePermissionsButton }
              variant={ variantTypes.text }
              disabled={ !destinationFolder || isFetching || isUserAdminLite }
              onClick={ onPermissionsChange }
              dataTest="copyMoveChangePermissionsButton"
            >
              <Icon
                className={ styles.changePermissionsIcon }
                type={ IconType.lock }
              />
              <span className={ styles.changePermissionsTitle }>Change Permissions</span>
            </Button>
          </Tooltip>
        </div>
      ) }
    </div>
  );

  return (
    <>
      { !conflictItems?.length ?
        (
          <Modal
            className={ styles.copyMoveModal }
            onCloseClicked={ closeCopyMoveModal }
            title={ title }
            footer={ footer }
            isVisible
            dataTest={ `${ actionName }Modal` }
          >
            <div>{ content }</div>
          </Modal>
        ) : (
          <ConflictModal
            onCloseConflictModal={ closeCopyMoveModal }
            action={ fetch }
            conflictType={ conflictType }
            successItems={ successItems }
            conflictItems={ conflictItems }
            destinationFolder={ destinationFolder }
            permissionGroups={ permissionGroups }
            isFetching={ isFetching }
            from={ fromFolder.current }
            fromStagingToPrimary={ fromStagingToPrimary }
          />
        ) }
    </>
  );
};

export default CopyMoveModal;
